import React, { useContext } from "react";
import { CartContext } from "../context/cart-context";
import Layout from "../components/layout";
import CartItem from '../components/cart-item';
import { Box, Grid, Container, Typography } from "@mui/material";
import Total from "../components/total";
import { Helmet } from "react-helmet";
// import IconBreadcrumbs from "../components/iconBreadCrumbs";

const CartPage = (product) => {

    const { cartItems, itemCount, total, increase, decrease, removeProduct, clearCart } = useContext(CartContext)
    // console.log(itemCount)
    const funcs = {increase, decrease, removeProduct}

    const clearFunc = {clearCart}

    return(

        <Layout>
            {/* <Box sx={{background: '#F8F6F1'}}> */}

            <Helmet>
                <title>Your Shopping Cart</title>
                <meta name="description" content={'Overview of the current shopping cart'} />
                <meta name="keywords" content="novello, oilive oil, novello olive oil, italian olive oil, cart, shopping cart, pantry, pantry bakery, pantry catering, salt bread, korean salt bread, grazing box, Orange counry catering, Los Angeles catering, team building lunch, executive breakfast, corporate event, fresh, lunch box" />

                <meta property="og:title" content={'Your Shopping Cart'} />
                <meta property="og:description" content={'Overview of the current shopping cart'} />
                {/* <meta property="og:image" content={URL of the image you want to use} /> */}
                <meta property="og:url" content={'https://thepantry.catering/shop'} />
                <meta property="og:type" content="website" />

                <meta name="twitter:title" content={'Your Shopping Cart'}  />
                <meta name="twitter:description" content={'Overview of the current shopping cart'} />
            </Helmet>

                <Container maxWidth='sm' >

                    <Box sx={{
                        display: 'flex', 
                        flexDirection: 'column', 
                        // height: '100%', 
                        minHeight: '80vh',
                        pt: '8rem',
                        pb: '3rem',
                        }}>
                        
                        {/* Bread crumbs is complete. */}
                        {/* <Box sx={{display: 'flex', width: '100%'}}>
                            <IconBreadcrumbs previous={'Home'} current={'Cart'}/>
                        </Box> */}

                        <Box sx={{
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center',
                            }}>
                            <Typography 
                                textAlign={'center'} 
                                sx={{
                                    fontFamily: 'Neuton',
                                    fontSize: {xs: 36, md: 48},
                                    lineHeight: 2,  
                                    pb: {md: '1rem'},
                                    width: '100%', 
                                    height: '100%'}}>
                                Summary
                            </Typography>
                        </Box>

                        <Box sx={{width: '100%'}}>

                            <Grid container spacing={1} rowSpacing={1} sx={{mt: '2%', mb: '2%'}}>

                                <Grid item xs={12}>
                                    <Total cartItems={cartItems} itemCount={itemCount} total={total} {...clearFunc}/> 
                                </Grid>

                                <Grid container item xs={12}>
                                    {
                                        cartItems.map(item => <CartItem {...item} key={item.product.id} {...funcs}/>)
                                    }
                                </Grid>

                            </Grid>

                        </Box>

                        <Box sx={{display: 'flex', alignItems: 'center', width: '100%', height: '4vh'}}>
                        </Box>

                    </Box>

                </Container>

        </Layout>
    )
}

export default CartPage