import React, {createContext, useEffect, useState} from "react";

export const ProductsContext = createContext()

export default function ProductContextProvider ({children}) {

    const stripe = require('stripe')(process.env.REACT_APP_SECRET_KEY);

    async function StripeProdcuts(stripe) {

        const products = await stripe.products.list({
            active: true,
            expand: ['data.default_price']
          });

        return products
    }

    const [products, setProducts] = useState(null)

    useEffect( () => {
        StripeProdcuts(stripe)
        .then( res => {
            setProducts(res.data)
        })

        return () => {}
    }, [])


    return (
        <ProductsContext.Provider value={{products}}>
            {children}
        </ProductsContext.Provider>
    )
}