import React, {useRef, useState} from "react"
import { Typography, Box, TextField, IconButton } from "@mui/material";
import EastIcon from '@mui/icons-material/East';
import { functions } from '../firebase/index';
import { httpsCallable } from "firebase/functions";
import ColorButton from "./coloredButton";


function NewsLetter() {

    const emailRef = useRef()

    const [err, setErr] = useState('')

    const newsLetter = httpsCallable(functions, "newsletter");


    async function handleSubmit() {

        try {
            console.log(emailRef.current.value)

            newsLetter({email: emailRef.current.value})
            .then( res => console.log(res))
            .catch()
        } catch {

        }

    }


    return(
        <Box sx={{
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'center', 
            width: {xs: '95%', md: '80%'}}}>

            <Box sx={{
                display: 'flex', 
                alignItems: 'center', 
                // background: 'yellow',
                width: '100%', 
                height: '100%', 
                marginBottom: '1rem',}}>
                <Typography 
                    textAlign={'left'}
                    sx={{
                        lineHeight: 2,
                        fontFamily: 'Neuton',
                        fontSize: {xs: 18, md: 20}
                        }}>
                    Your journey with Pantry starts here
                </Typography>
            </Box>

            <Box sx={{
                display: 'flex', 
                flexDirection: {xs: 'column', sm: 'row'},
                alignItems: 'center', 
                // background: 'yellow',
                width: '100%', 
                height: '100%', 
                marginBottom: '1rem',}}>

                <Box sx={{
                    display: 'flex', 
                    alignItems: 'center', 
                    width: {xs: '100%', sm: '80%'}, 
                    mb: {xs: '1rem', sm: 0}, 
                    height: '100%', }}>
                    <TextField 
                        variant='standard'
                        // fullWidth 
                        required 
                        label="Email"
                        type="email"
                        color="secondary"
                        inputRef={emailRef} 
                        InputLabelProps={{
                            sx: {
                            fontFamily: 'Neuton',
                            fontSize: 16,
                            }
                        }} 
                        sx={{width: {xs: '100%', sm: '95%'}}}
                        />
                </Box>
                <Box sx={{
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    width: {xs: '100%', sm: '20%'}, 
                    height: '100%', }}>

                        <ColorButton
                            variant='contained' 
                            disableElevation
                            sx={{
                                width: {xs: '100%', sm: '95%'},
                     
                            }}
                            >
                                SUBSCRIBE
                        </ColorButton>
                </Box>
            </Box>

            <Box sx={{
                display: 'flex', 
                alignItems: 'center', 
                // background: 'yellow',
                width: '100%', 
                height: '100%', 
                // marginBottom: '1rem',
                }}>
                {/* 3 */}
                <Typography
                    textAlign={'justify'}
                    sx={{
                        lineHeight: 1.5,
                        fontFamily: 'Neuton',
                        // marginBottom: '16px',
                        fontWeight: 300,
                        fontSize: {xs: 16, md: 18}
                        }}>
                    For exciting news, exclusive events, & delicious products subscribe to our newsletter.
                </Typography>
            </Box>

        </Box>
    )
}

export default NewsLetter