import React from "react";
import Layout from "../components/layout";
// import FeaturedProduct from "../components/featured-product";
// import { ProductsContext } from "../context/products-context";
import { 
    Typography, 
    Box, 
    Container,
    // Button, 
    // Grid 
} from "@mui/material";
import { Helmet } from 'react-helmet';
import { grey } from "@mui/material/colors";
import IconBreadcrumbs from "../components/iconBreadCrumbs";

export default function Deliveries() {

    return(
        <div>
            <Helmet>
                <title>Delivery information</title>
                {/* NOTE: HAS TO BE CHANGED */}
                <meta name="description" content={'affordable private chef, and catering for events'} />
                <meta name="keywords" content="novello, oilive oil, novello olive oil, italian olive oil, best in OC, best in LA, affordable private chef, catering, private event, baby shower, engagement, corporate dining" />

                <meta property="og:title" content={'Welcome to The Pantry'} />
                <meta property="og:description" content={'affordable private chef, and catering for events'} />
                {/* <meta property="og:image" content={URL of the image you want to use} /> */}
                <meta property="og:url" content={'https://thepantry.catering'} />
                <meta property="og:type" content="website" />

                <meta name="twitter:title" content={'Welcome to The Pantry'} />
                <meta name="twitter:description" content={'affordable private chef, and catering for events'} />
            </Helmet>
            <Layout>

                <Container maxWidth='lg'>

                    <Box sx={{
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        pt: '8rem',
                        pb: '3rem',
                        }}>                       

                        <Box sx={{display: 'flex', width: '100%'}}>
                            <IconBreadcrumbs previous={'Home'} current={'Shop'}/>
                        </Box>

                        <Box sx={{
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center',
                            // background: 'red'
                            }}>
                            <Typography 
                                textAlign={'center'} 
                                sx={{
                                    fontFamily: 'Neuton',
                                    fontSize: {xs: 36, md: 48},
                                    lineHeight: 2,  
                                    // fontWeight: 800, 
                                    // pt: '4%',
                                    pb: {md: '1rem'},
                                    width: '100%', 
                                    height: '100%'}}>
                                Delivery Information & Charges
                            </Typography>
                        </Box>

                        <Box sx={{
                            display: 'flex', 
                            flexDirection: {xs: 'column', md: 'row'},
                            alignItems: 'center',
                            justifyContent: 'center', 
                            width: '100%',
                            pt: '3rem',
                            }}>

                            {/* <Box sx={{width: '20%',}}>

                                <Typography 
                                    textAlign={'left'}
                                    sx={{
                                        width: '95%',
                                        fontSize: {xs: 24, md: 28},
                                        lineHeight: {xs: 1.5, md: 2},
                                        pb: '1rem',
                                        fontFamily: 'Neuton',
                                        fontWeight: 300, 
                                        color: grey[900]}}>
                                        U.S. Delivery
                                </Typography>
                                
                            </Box> */}

                            <Box sx={{display: 'flex', flexDirection: 'column', width: '100%',}}>

                                <Typography 
                                    textAlign={'justify'}
                                    sx={{
                                        width: '95%',
                                        fontSize: {xs: 18, md: 20},
                                        lineHeight: {xs: 1.5, md: 2},
                                        pb: '1rem',
                                        fontFamily: 'Neuton',
                                        fontWeight: 500, 
                                        color: grey[900]}}>
                                        STANDARD DELIVERY - 5 to 7 Business Days
                                </Typography>

                                <Typography 
                                    textAlign={'justify'}
                                    sx={{
                                        width: '95%',
                                        fontSize: {xs: 18, md: 20},
                                        lineHeight: {xs: 1.5, md: 2},
                                        pb: '1rem',
                                        fontFamily: 'Neuton',
                                        fontWeight: 300, 
                                        color: grey[900]}}>
                                        Deliveries are made Monday to Friday. Standard delivery takes up to 7 working days from the date when the order was placed.  
                                        Our cut off times are 12pm (Pacific Time) Monday to Friday. We can only send by standard delivery to HI and AK, excluding 
                                        fresh items, and alcohol.
                                </Typography>

                                <Typography 
                                    textAlign={'justify'}
                                    sx={{
                                        width: '95%',
                                        fontSize: {xs: 18, md: 20},
                                        lineHeight: {xs: 1.5, md: 2},
                                        pb: '1rem',
                                        fontFamily: 'Neuton',
                                        fontWeight: 500, 
                                        color: grey[900]}}>
                                        NEXT WORKING DAY DELIVERY - 1 to 3 Business Days
                                </Typography>

                                <Typography 
                                    textAlign={'justify'}
                                    sx={{
                                        width: '95%',
                                        fontSize: {xs: 18, md: 20},
                                        lineHeight: {xs: 1.5, md: 2},
                                        pb: '1rem',
                                        fontFamily: 'Neuton',
                                        fontWeight: 300, 
                                        color: grey[900]}}>
                                        Deliveries are made Monday to Friday but are subject to availability - Next Working Day delivery slots are limited and 
                                        any date may become fully booked without notice. Orders must be placed by 6pm (Pacific Time) Monday to Friday. 
                                </Typography> 

                                <Typography 
                                    textAlign={'justify'}
                                    sx={{
                                        width: '95%',
                                        fontSize: {xs: 18, md: 20},
                                        lineHeight: {xs: 1.5, md: 2},
                                        pb: '1rem',
                                        fontFamily: 'Neuton',
                                        fontWeight: 500, 
                                        color: grey[900]}}>
                                        DELIVERY DATE EXCLUSIONS
                                </Typography>

                                <Typography 
                                    textAlign={'justify'}
                                    sx={{
                                        width: '95%',
                                        fontSize: {xs: 18, md: 20},
                                        lineHeight: {xs: 1.5, md: 2},
                                        fontFamily: 'Neuton',
                                        fontWeight: 300, 
                                        color: grey[900]}}>
                                    24th - 26th December 2024 | Christmas 
                                </Typography>

                                <Typography 
                                    textAlign={'justify'}
                                    sx={{
                                        width: '95%',
                                        fontSize: {xs: 18, md: 20},
                                        lineHeight: {xs: 1.5, md: 2},
                                        fontFamily: 'Neuton',
                                        fontWeight: 300, 
                                        color: grey[900]}}>
                                    1st - 2nd January 2025 | New Year 
                                </Typography>

                                <Typography 
                                    textAlign={'justify'}
                                    sx={{
                                        width: '95%',
                                        fontSize: {xs: 18, md: 20},
                                        lineHeight: {xs: 1.5, md: 2},
                                        fontFamily: 'Neuton',
                                        fontWeight: 300, 
                                        color: grey[900]}}>
                                    20th January 2025 | Martin Luther King Day
                                </Typography>

                                <Typography 
                                    textAlign={'justify'}
                                    sx={{
                                        width: '95%',
                                        fontSize: {xs: 18, md: 20},
                                        lineHeight: {xs: 1.5, md: 2},
                                        fontFamily: 'Neuton',
                                        fontWeight: 300, 
                                        color: grey[900]}}>
                                    24th - 26th May 2025 | Memorial Day
                                </Typography>

                                <Typography 
                                    textAlign={'justify'}
                                    sx={{
                                        width: '95%',
                                        fontSize: {xs: 18, md: 20},
                                        lineHeight: {xs: 1.5, md: 2},
                                        fontFamily: 'Neuton',
                                        fontWeight: 300, 
                                        color: grey[900]}}>
                                    3rd - 6th May 2025 | Independence Day
                                </Typography>
                                
                                {/* <Typography 
                                    textAlign={'justify'}
                                    sx={{
                                        width: '95%',
                                        fontSize: {xs: 18, md: 20},
                                        lineHeight: {xs: 1.5, md: 2},
                                        fontFamily: 'Neuton',
                                        fontWeight: 300, 
                                        color: grey[900]}}>
                                    29th March – 2nd April | Easter Holiday
                                </Typography>
                                
                                <Typography 
                                    textAlign={'justify'}
                                    sx={{
                                        width: '95%',
                                        fontSize: {xs: 18, md: 20},
                                        lineHeight: {xs: 1.5, md: 2},
                                        fontFamily: 'Neuton',
                                        fontWeight: 300, 
                                        color: grey[900]}}>
                                    6th – 7th May | Early May Bank Holiday
                                </Typography> */}
                                
                                {/* <Typography 
                                    textAlign={'justify'}
                                    sx={{
                                        width: '95%',
                                        fontSize: {xs: 18, md: 20},
                                        lineHeight: {xs: 1.5, md: 2},
                                        fontFamily: 'Neuton',
                                        fontWeight: 300, 
                                        color: grey[900]}}>
                                    27th – 28th May | Late May Bank Holiday
                                </Typography>
                                
                                <Typography 
                                    textAlign={'justify'}
                                    sx={{
                                        width: '95%',
                                        fontSize: {xs: 18, md: 20},
                                        lineHeight: {xs: 1.5, md: 2},
                                        fontFamily: 'Neuton',
                                        fontWeight: 300, 
                                        color: grey[900]}}>
                                    26th – 27th August | Summer Bank Holiday
                                </Typography> */}
                                

                                
                                {/* <Typography 
                                    textAlign={'justify'}
                                    sx={{
                                        width: '95%',
                                        fontSize: {xs: 18, md: 20},
                                        lineHeight: {xs: 1.5, md: 2},
                                        fontFamily: 'Neuton',
                                        fontWeight: 300, 
                                        color: grey[900]}}>
                                    Additional delivery dates (we will be delivering on these dates as extra):
                                </Typography>
                                
                                <Typography 
                                    textAlign={'justify'}
                                    sx={{
                                        width: '95%',
                                        fontSize: {xs: 18, md: 20},
                                        lineHeight: {xs: 1.5, md: 2},
                                        fontFamily: 'Neuton',
                                        fontWeight: 300, 
                                        color: grey[900]}}>
                                    Sunday 10th March | Mothering Sunday (Mother’s Day) 
                                </Typography> */}


                            </Box>

                        </Box>

                    </Box>

                </Container>
            </Layout>
        </div>
    )
}