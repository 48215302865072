import React, { useContext, useState, useEffect } from "react";
import { ProductsContext } from "../context/products-context";
import Layout from "./layout";
import {
    useNavigate,
    useParams,
  } from "react-router-dom";
import { Typography, Box, Container, AppBar, Toolbar, Stack, Card, CardMedia } from "@mui/material";
import { CartContext } from "../context/cart-context";
import { isInCart } from "../helpers";
import { grey } from "@mui/material/colors";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import ColorButton from "./coloredButton";
import TitleBlock from "./demoTitleBlock";
import NewsLetter from "./demoNewsLetter";
import NewsLetter2 from "./demoNewsLetter2";
import PantryProgress from "./pantryCircularProgress";
// import { alpha } from '@mui/material/styles';
// import Carousel from 'react-multi-carousel';
// import 'react-multi-carousel/lib/styles.css';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{pt: 3, pb: 3}}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

function getRandomInts(others) {
    let num1 = Math.floor(Math.random() * others.length); // Random integer between 0 and n
    let num2;

    do {
        num2 = Math.floor(Math.random() * others.length); // Another random integer between 0 and n
    } while (num1 === num2); // Ensure num2 is different from num1

    return [num1, num2];
}
  
function PantryItem({match}) {

    const { id } = useParams()
    const [loading, setLoading] = useState(true)
    const { products } = useContext(ProductsContext)
    
    const { addProduct, cartItems } = useContext(CartContext)
    const navigate = useNavigate()
    const [product, setProduct] = useState(null)
    const [desk, setDesks] = useState([])
    const [aromas, setAromas] = useState([])
    const [itemsInCart, setItems] = useState(null)
    const [relatedProducts, setRelatedProducts] = useState([])
    // const [value, setValue] = useState(1);


    useEffect(() =>{

        if (products) {

            const currentProduct = products.find(item => item.metadata.webid === id)

            if (currentProduct) {

                const otherProducts = products.filter(item => item.metadata.webid !== id)
                  
                const [randomInt1, randomInt2] = getRandomInts(otherProducts);

                setItems(isInCart(currentProduct, cartItems))
                setProduct(currentProduct)
                setDesks(currentProduct.description)
                setAromas(currentProduct.metadata.aromas.split(', '))
                setRelatedProducts(
                    [otherProducts[randomInt1], otherProducts[randomInt2]]
                )
                setLoading(false)

            } else {
                return navigate('/')
            }

        } else {
            console.log("No product found.")
        }

        return( ()=>{} )

    }, [cartItems, id, navigate, products])

    return(
        <div>
            <Helmet>
                {/* <title>{name} Details</title> */}
                <meta name="description" content={'The pantry catering item details'} />
                <meta name="keywords" content="pantry, pantry bakery, pantry catering, event catering, corporate catering, corporate lunch box, salt bread, korean salt bread, grazing box, Orange counry catering, Los Angeles catering, team building lunch, executive breakfast, corporate event, fresh, lunch box" />

                {/* <meta property="og:title" content={`${name} details`} /> */}
                <meta property="og:description" content={'The pantry catering item details'} />
                {/* <meta property="og:image" content={URL of the image you want to use} /> */}
                <meta property="og:url" content={`https://thepantry.catering/product/${id}`} />
                <meta property="og:type" content="website" />

                <meta name="twitter:title" content={`${id} details`}  />
                <meta name="twitter:description" content={'The pantry catering item details'} />
            </Helmet>

            <Layout>
                <Container maxWidth='md'>
                    {
                        loading ? 

                        <PantryProgress />

                        :

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center', 
                            justifyContent: 'center',
                            marginTop: '3rem',
                            height: '100%',
                            width: '100%',
                            pt: '6rem',
                            pb: '3rem',
                            }}>


                            {/* BreadCrumbs done. */}
                            {/* <Box sx={{display: 'flex', width: '100%'}}>
                                <IconBreadcrumbs previous={'Home'} current={'Shop'}/>
                            </Box> */}
                            
                            {/* Carousel is done. */}
                            <Box sx={{
                                display: 'flex', 
                                alignContent: 'center', 
                                width: {xs: '80%', md: '60%'}, 
                                mt: '1rem',
                                }}>

                                <Carousel 
                                    showArrows={true}
                                    showStatus={false}
                                    autoPlay={false}
                                    // infiniteLoop={true}
                                    showIndicators={true} 
                                    showThumbs={false}
                                    >
                                    {
                                        product.images.map( (img, index) => 
                                            <div key={index}>
                                                <img 
                                                    src={img} 
                                                    width={'50%'} 
                                                    style={{borderRadius: '0.5rem'}}
                                                    alt="View from top"/>
                                            </div>
                                        )
                                    }

                                </Carousel>
                            </Box>

                            {/* Product Title is done. */}
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                height: '100%',
                                width: '100%',
                                mt: '1rem',
                                }}>
                                <TitleBlock 
                                    Name={product.name} 
                                    Size={product.metadata.size} 
                                    Location={product.metadata.location}
                                    Variety={product.metadata.olives}
                                    />
                            </Box>

                            {/* Price is done. */}
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                height: '100%',
                                width: '100%',
                                mt: '1rem',
                                }}>
                                <Typography 
                                    gutterBottom
                                    sx={{
                                        fontSize: {xs: 20, md: 24}, 
                                        lineHeight: 1.5,
                                        fontFamily: 'Neuton', 
                                        fontWeight: 500}}>
                                        ${0.01 * product.default_price.unit_amount}
                                </Typography>
                            </Box>

                            {/* Description tabs is done. */}
                            <Box sx={{ 
                                width: '100%', 
                                // background: 'teal' 
                                }}>
                                {/* <Tabs 
                                    variant='fullWidth'
                                    value={value} 
                                    onChange={handleChange}
                                    textColor="Secondary"
                                    indicatorColor="primary" 
                                    centered
                                    sx={{fontFamily: 'Neuton'}}
                                    >
                                    <Tab 
                                        value={1} 
                                        label="Description" 
                                        sx={{
                                            fontFamily: 'Neuton',
                                            fontWeight: 500, 
                                            fontSize: {xs: 16, md: 20},
                                            textTransform: 'none',
                                            }}/> */}
                                    {/* <Tab 
                                        value={2} 
                                        label="Nutritional" 
                                        sx={{
                                            fontFamily: 'inherit',
                                            fontWeight: 500, 
                                            fontSize: {xs: 16, md: 20},
                                            textTransform: 'none',
                                        }}/> */}
                                {/* </Tabs> */}
                                {/* <TabPanel value={value} index={1} dir={theme.direction}> */}

                                    {/* <AA d={desk}/> */}

                                    <Typography
                                        // key={i} 
                                        textAlign={'justify'} 
                                        sx={{
                                            marginBottom: '14px',
                                            fontFamily: 'Neuton',
                                            fontWeight: 300,
                                            fontSize: {xs: 16, md: 18}
                                            }}>
                                            {product.description}
                                    </Typography>

                                {/* </TabPanel> */}

                                {/* <TabPanel value={value} index={2} dir={theme.direction}>
                                    Item Two
                                </TabPanel> */}
                            </Box>

                            {/* Aroma and Notes */}
                            <Box sx={{ 
                                width: '100%', 
                                }}>
                                <Typography 
                                    textAlign={'left'} 
                                    gutterBottom
                                    sx={{
                                        fontSize: {xs: 28, md: 32}, 
                                        lineHeight: 1.5,
                                        fontFamily: 'Neuton', 
                                        fontWeight: 300,
                                        color: grey[800],
                                        }}>
                                    Aromas
                                </Typography>
                            </Box>

                            <Box sx={{
                                // display: 'flex',
                                // alignItems: 'center',
                                // justifyContent: 'space-evenly',
                                // background: 'red',
                                width: '100%',
                                }}>
                                <Stack direction="row" spacing={2} useFlexGap>
                                    {aromas.map(i=> 
                                    <Box 
                                        key={i}
                                        sx={{ 
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%',
                                            }}>
                                            {/* <img src={i.image} width={'50px'} alt={i.name}></img> */}
                                            <Typography 
                                                textAlign={'center'} 
                                                sx={{
                                                    marginBottom: '14px',
                                                    fontFamily: 'Neuton',
                                                    fontStyle: 'italic',
                                                    fontWeight: 500,
                                                    fontSize: {xs: 16, md: 18},
                                                    width: '100%',
                                                    }}>
                                                    {i}
                                            </Typography>
                                        </Box>
                                    )}
                                </Stack>
                            </Box>

                            {/* Add to Cart button is done. */}
                            <Box sx={{
                                display: {xs: 'none', md: 'flex'},
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                                width: '100%',
                                }}>
                                <ColorButton 
                                    variant='contained' 
                                    disableElevation
                                    sx={{
                                        width: '40%',
                                        mt: '1rem',
                                        mb: '2rem',
                                    }}
                                    onClick={() => {addProduct(product)}}>
                                    ADD TO CART
                                </ColorButton>
                            </Box>

                            {/* Delivery Box is completed. */}
                            <Box
                                sx={{
                                    display: 'flex', 
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center', 
                                    width: '100%', 
                                    mt: '1rem',
                                    background: '#F8F6F1',
                                    }}>
                                <Box
                                    sx={{
                                        display: 'flex', 
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center', 
                                        width: '95%',
                                        m: '1rem',
                                        }}>
                                    <NewsLetter2/>
                                </Box>
                            </Box>

                            {/* Related Product Box */}
                            <Box sx={{
                                display: 'flex',
                                width: '100%', 
                                mt: '1rem',
                                }}>
                                <Typography 
                                    textAlign={'left'}
                                    sx={{
                                        lineHeight: 2,
                                        fontFamily: 'Neuton',
                                        fontSize: {xs: 28, md: 32}, 
                                        fontWeight: 400,
                                        color: grey[800],
                                        }}>
                                    Related Products
                                </Typography>
                            </Box>

                            <Box sx={{
                                display: 'flex',
                                flexDirection: {xs: 'column', md: 'row'},
                                alignItems: 'center',
                                width: '100%', 
                                mt: '1rem',
                                }}>

                                <Card 
                                    variant='outlined' 
                                    sx={{ 
                                        width: {xs: '100%', sm: '80%', md: '100%'},
                                        display: 'flex', 
                                        alignItems: 'center', 
                                        justifyContent: 'center',
                                        borderWidth: 0, 
                                        mr: {xs: 0, md: '1rem'},
                                        '&:hover': {
                                            zIndex: 1,
                                            borderWidth: 1,
                                        },
                                        }}>
                                        <CardMedia
                                            component={'img'}
                                            sx={{ width: '80%'}}
                                            image={relatedProducts[0].images[0]}
                                            title={relatedProducts[0].name}
                                            onClick={() => {navigate(`/product/${relatedProducts[0].metadata.webid}`)}}
                                            />
                                </Card>

                                <Card 
                                    variant='outlined' 
                                    sx={{ 
                                        width: {xs: '100%', sm: '80%', md: '100%'},
                                        display: 'flex', 
                                        alignItems: 'center', 
                                        justifyContent: 'center', 
                                        borderWidth: 0,                               
                                        ml: {xs: 0, md: '1rem'},
                                        mt: {xs: '1rem', md: 0},
                                        '&:hover': {
                                            zIndex: 1,
                                            borderWidth: 1,
                                        },
                                        }}>
                                    <CardMedia
                                        component={'img'}
                                        sx={{ width: '80%'}}
                                        image={relatedProducts[1].images[0]}
                                        title={relatedProducts[1].name}
                                        onClick={() => {navigate(`/product/${relatedProducts[1].metadata.webid}`)}}
                                        />
                                </Card>

                            </Box>

                            {/* News letter is completed. */}
                            <Box sx={{
                                display: 'flex',
                                width: '100%', 
                                mt: '1rem',
                                }}>
                                <Typography 
                                    textAlign={'left'}
                                    sx={{
                                        lineHeight: 2,
                                        fontFamily: 'Neuton',
                                        fontSize: {xs: 28, md: 32}, 
                                        fontWeight: 400,
                                        color: grey[800],
                                        }}>
                                    Join Newsletter 
                                </Typography>
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex', 
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center', 
                                    width: '100%', 
                                    mt: '1rem',
                                    background: '#F8F6F1',
                                    border: 'blue'
                                    }}>
                                <Box
                                    sx={{
                                        display: 'flex', 
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center', 
                                        width: '95%',
                                        m: '1rem',
                                        }}>
                                    <NewsLetter/>
                                </Box>
                            </Box>

                            {/* Floating button is done.*/}
                            <AppBar 
                                position='fixed' 
                                elevation={0}
                                sx={{
                                    display: {xs: 'flex', md: 'none'},
                                    width: '100%', 
                                    height: '5rem', 
                                    justifyContent: 'center', 
                                    top: 'auto', 
                                    bottom: 0, 
                                    background: '#F3EDE0'}}>
                                    <Toolbar sx={{                
                                        borderTop: 1,
                                        borderColor: 'primary.main'}}>
                                    <ColorButton 
                                        // disabled={ variation === '' ? true : false}
                                        variant='contained' 
                                        disableElevation
                                        sx={{
                                            width: '98%',
                                            mt: {xs: '2%', md: '2%'},
                                            mb: {xs: '2%', md: '2%'},
                                        }}
                                        onClick={() => {addProduct(product)}}>
                                            ADD TO CART
                                    </ColorButton>
                                </Toolbar>
                            </AppBar>

                        </Box>
                    }
                </Container>
            </Layout>

        </div>
    )

}


export default PantryItem